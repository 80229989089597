<template>
	<div id="buy-ins">
		<h1>{{ languageStrings.webPayCashOut }}</h1>
		<p style="text-align: center">{{ busyText }}</p>
		<div v-if="countryCode !== 'MX' && casinoList.length > 1">
			<h2>{{ languageStrings.selectCasino }}</h2>
			<DropdownMenu id="casino-select" :casinoList="casinoList" :languageStrings="languageStrings" />
		</div>
		<div v-if="selectedCasino?.id" class="buy-ins-container">
			<div class="content-container">
				<div v-if="selectedCasino?.id" id="balances">
					{{ languageStrings.currentCashBalance }}:
					<p class="current-balance">
						{{
							systemCurrencyTool?.formatFromAU(
								selectedCasino.balance.regularAU,
								systemCurrencyTool.displayType.full
							)
						}}
					</p>
				</div>
				<div
					v-if="webPayOptions?.nuvei?.allowCashOutLATAM || webPayOptions?.nuvei?.allowCashOutSTPmex"
					id="payment-type-section"
				>
					<label for="offer-payment-type" class="center">{{ languageStrings.paymentType }}:</label>
					<select
						id="offer-payment-type"
						class="curved-border"
						v-model="paymentType"
						:placeholder="languageStrings.selectPaymentType"
					>
						<option v-if="allowCashOutLATAM">LATAM</option>
						<option v-if="allowCashOutSTPmex">STPmex</option>
					</select>
				</div>
				<div class="input-section" v-if="selectedCasino?.id">
					<label for="toPlayerCashCOC">{{ languageStrings.webPayCashOutCOC }}</label>
					<span v-if="webPayOptions?.nuvei?.cashOutMinimumCOC"
						>Min:
						{{
							systemCurrencyTool.formatCurrency(
								webPayOptions?.nuvei?.cashOutMinimumCOC,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}</span
					>
					<span v-if="webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC"
						>Max:
						{{
							systemCurrencyTool.formatCurrency(
								webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}</span
					>
					<span>
						<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
							systemCurrencyTool.currencyInfo.symbol
						}}</span>
						<input
							type="number"
							id="toPlayerCashCOC"
							name="displayCOC"
							v-model="toPlayerCashCOC"
							:min="webPayOptions?.nuvei?.cashOutMinimumCOC"
							:max="webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC"
							:placeholder="languageStrings.fromPlayerCash"
							autocomplete="off"
						/>
						<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
							systemCurrencyTool.currencyInfo.symbol
						}}</span>
					</span>
				</div>
				<div class="button-container">
					<button class="btn buy-in-btn curved-border" id="commit-cash-out" @click="order()">
						{{ languageStrings.webPayCashOut }}
					</button>
				</div>
				<div id="nuvei-container" :class="nuveiShow ? 'show' : ''">
					<button class="btn" @click="cancelNuvei()">{{ buttonText }}</button>
					<div id="nuvei-iframe-container" v-if="safeChargeApiResult?.redirectUrl">
						<iframe
							id="nuvei-iframe"
							:src="safeChargeApiResult.redirectUrl"
							@load="checkIframeURL()"
						></iframe>
					</div>
				</div>
				<div v-if="transactions?.length > 0">
					<div>
						<h3>{{ languageStrings.lastWebPayTransaction }}</h3>
						<TransactionsTableMobile
							v-if="isMobile"
							:transactions="transactions"
							:systemCurrencyTool="systemCurrencyTool"
							:languageStrings="languageStrings"
						/>
						<TransactionsTable
							v-if="!isMobile"
							:transactions="transactions"
							:systemCurrencyTool="systemCurrencyTool"
							:languageStrings="languageStrings"
						/>
					</div>
				</div>
				<dialog id="nuvei-dialog" @click="closeNuveiDialog()">
					<div>
						<h2>{{ languageStrings.transactionResult }}</h2>
						<p
							v-if="
								rabbitsfootMessage?.status !== 'RefusedByProvider' &&
								rabbitsfootMessage?.result !== 'ERROR'
							"
						>
							{{ checkoutStatus }}<br />
							{{
								systemCurrencyTool.formatCurrency(
									toPlayerCashCOC,
									systemCurrencyTool.displayType.minorOrFull
								)
							}}
						</p>
						<div v-else>
							<p>{{ languageStrings.refusedByProvider }}</p>
							<p v-if="rabbitsfootMessage?.statusAdditionalInfo">
								{{ languageStrings.additionalInfo }}: {{ rabbitsfootMessage.statusAdditionalInfo }}
							</p>
						</div>
						<div id="btn-container">
							<router-link class="btn" :title="languageStrings.viewBalances" to="/">{{
								languageStrings.viewBalances
							}}</router-link>
						</div>
					</div>
				</dialog>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import CurrencyTool from "@/dependencies/currencyTool";
import sharedScripts from "@/dependencies/sharedScripts";
import DropdownMenu from "@/components/DropdownMenu.vue";
import TransactionsTableMobile from "@/components/TransactionsTableMobile.vue";
import TransactionsTable from "@/components/TransactionsTable.vue";

export default {
	name: "WebPayCashOut",
	inheritAttrs: false,
	props: {
		casinoList: Array,
		playerState: Object,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
	},
	components: {
		DropdownMenu,
		TransactionsTableMobile,
		TransactionsTable,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			cashOutCurrency: {},
			inGameCurrencyInfo: {},
			nuveiResult: {},
			nuveiDialog: null,
			selectedCasino: null,
			casinoId: null,
			nuveiShow: false,
			rabbitsfootMessage: null,
			webPayOptions: {},
			toPlayerCashCOC: null,
			paymentType: null,
			allowCashOutLATAM: false,
			allowCashOutSTPmex: false,
			safeChargeApiResult: null,
			buttonText: "",
			transactionStatus: null,
			transactions: null,
			checkoutStatus: "",
		};
	},
	watch: {
		casinoId() {
			if (this.casinoId) this.getWebPayOptions();
		},
	},
	created() {
		this.eventBus.on("casinoSelected", (payload) => {
			this.selectedCasino = payload;
			this.casinoId = payload.id;
			this.inGameCurrencyInfo = new CurrencyTool(payload.inGameCurrencyInfo, this.localeString);
		});
		this.eventBus.on("showNuveiWidgit", () => {
			this.nuveiShow = true;
		});
		this.eventBus.on("handelNuveiError", (payload) => {
			this.rabbitsfootMessage = payload;
			this.nuveiShow = false;
			this.showNuveiDialog();
		});
		this.eventBus.on("nuveiChecoutResult", (payload) => {
			this.nuveiResult = payload;
			this.nuveiShow = false;
			this.showNuveiDialog();
		});
		onBeforeUnmount(() => {
			window.document.removeEventListener("transactionComplete", this.handlPayCompleteEvent, false);
			this.eventBus.off("casinoSelected");
			this.eventBus.off("showNuveiWidgit");
			this.eventBus.off("handelNuveiError");
			this.eventBus.off("nuveiChecoutResult");
		});
		if (this.casinoId) this.getWebPayOptions();
	},
	mounted() {
		if (this.countryCode === "MX" || this.casinoList.length === 1)
			this.eventBus.emit("casinoSelected", this.pickDefaultCasino());
		this.nuveiDialog = document.getElementById("nuvei-dialog");
		window.document.addEventListener("transactionComplete", this.handlPayCompleteEvent, false);
	},
	methods: {
		pickDefaultCasino() {
			let casinoIdSelected;
			casinoIdSelected = this.casinoList.filter((casino) => casino.isEnabled === true)[0];
			return casinoIdSelected;
		},
		checkIframeURL() {},
		handlPayCompleteEvent(e) {
			this.checkoutStatus = e.detail;
			this.showNuveiDialog();
			this.getFirstTransaction();
			this.eventBus.emit("updateBalance", this.playerState);
			this.eventBus.emit("casinoSelected", this.selectedCasino);
		},
		async getWebPayOptions() {
			this.busyText = this.languageStrings.loadingBuyIns;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/webpay/options`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.playerState.casinoId) params.set("casinoId", this.playerState.casinoId);

			params.set("casinoId", this.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.webPayOptions = dataJson;
				this.availableOffers = dataJson.availableOffers;
				this.inGameCurrencyInfo = dataJson.casino.currencyInfo;

				if (this.webPayOptions?.nuvei?.allowCashOutLATAM)
					this.allowCashOutLATAM = this.webPayOptions.nuvei.allowCashOutLATAM;
				if (this.webPayOptions?.nuvei?.allowCashOutSTPmex)
					this.allowCashOutSTPmex = this.webPayOptions.nuvei.allowCashOutSTPmex;

				let _state = this.playerState;
				_state.morePersonalInfoNeeded = this.webPayOptions.taxAmlRestrictions.morePersonalInfoNeeded;
				this.eventBus.emit("updatePlayerState", _state);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		async order() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.loadingWebPayCashout;
			this.buttonText = this.languageStrings.cancelTransaction;

			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				hasErrors = true;
			}

			if (!this.toPlayerCashCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterACashOutAmount;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (isNaN(this.toPlayerCashCOC)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterNumberOnly;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (
				!this.paymentType &&
				(this.webPayOptions?.nuvei?.allowCashOutLATAM || this.webPayOptions?.nuvei?.allowCashOutSTPmex)
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustSelectAPaymentType;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (
				this.toPlayerCashCOC < this.webPayOptions?.nuvei?.cashOutMinimumCOC ||
				this.toPlayerCashCOC > this.webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `Transaction amount allowed is between ${
					this.webPayOptions.nuvei.cashOutMinimumCOC
				} and ${
					this.webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC ||
					this.webPayOptions?.nuvei?.cashOutMaximumCOC
				}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (
				this.systemCurrencyTool.toAUFromCurrency(this.toPlayerCashCOC) > this.selectedCasino.balance.regularAU
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.notEnoughFundsInPlayerAccount;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.toPlayerCashCOC < this.webPayOptions.nuvei.cashOutMinimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustMeetMinimumCashOutValue;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (hasErrors === true) return;

			let body = {
				casinoId: this.webPayOptions.casino.id,
				toPlayerCashCOC: this.toPlayerCashCOC,
				provider: "Nuvei",
			};

			let requestUrl = new URL("/api/v1/webpay/cashout/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				this.transaction = await response.json();

				if (this.transaction?.status === "EmailNotVerified") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.emailNotVerifiedPleaseGoToYourUserProfileAndVerifyYourEmail;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);

				if (this.status.message === "Success") {
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.webPayInitiated;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.performNuveiCashoutCheckout();
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		performNuveiCashoutCheckout() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.waitingForAccountCaptureResponseFromNuvei;

			// Initialize the Nuvei/SafeCharge API.
			let safeChargeApi = SafeCharge({
				env: this.webPayOptions.nuvei.environment,
				sessionToken: this.transaction.sessionId,
				merchantId: this.webPayOptions.nuvei.merchantId,
				merchantSiteId: this.webPayOptions.nuvei.merchantSiteId,
			});

			let paymentTypeString = "";
			if (this.paymentType === "LATAM") paymentTypeString = "apmgw_LATAM_Payouts";
			if (this.paymentType === "STPmex") paymentTypeString = "apmgw_STPmex";

			// Tell Nuvei that we want a URL to an "account capture" form.
			const captureParams = {
				sessionToken: this.transaction.sessionId,
				merchantId: this.webPayOptions.nuvei.merchantId,
				merchantSiteId: this.webPayOptions.nuvei.merchantSiteId,
				paymentMethod: paymentTypeString,
				userTokenId: this.transaction.transaction.userId,
				currencyCode: this.webPayOptions.cashOutCurrency.iso4217Code,
				countryCode: this.webPayOptions.nuvei.merchantTwoLetterCountryCode,
				languageCode: this.systemCurrencyTool.cultureCode.split("-")[0],
				urlDetails: {
					successUrl: `${window.parent.location.href}/cashout-finished.html?ppp_status=OK`,
					failureUrl: `${window.parent.location.href}/cashout-finished.html?ppp_status=FAIL`,
					pendingUrl: `${window.parent.location.href}/cashout-finished.html?ppp_status=PENDING`,
					notificationUrl: `${this.rabbitsfootHostUrl}/api/v1/webpay/callback/nuvei/capture/${this.transaction.transaction.id}`,
				},
			};
			safeChargeApi.accountCapture(captureParams, this.handleCaptureResult);
		},
		async handleCaptureResult(result) {
			if (result.status !== "SUCCESS") {
				this.busyText = result.status;
				this.cancelRfTransaction();
				return;
			}
			this.buttonText = this.languageStrings.closeWebPayWindow;
			this.busyText = "";
			this.nuveiShow = true;
			this.safeChargeApiResult = result;
		},
		async getFirstTransaction() {
			// Check if user has any WebPay transactions

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/webpay/transaction`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			// Check if there is at least one transaction
			params.set("limit", 1);
			if (this.playerState.userId) params.set("playerUserId", this.playerState.userId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				this.transactions = await response.json();
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		async cancelRfTransaction() {
			this.busyText = this.languageStrings.cancellingTransaction;

			// Tell the RF server that the transaction isn't going to happen.  (We might want to return them to
			// the buy-in offer stage so they can start over, though.)
			let body = {
				webTranId: this.transaction.id,
				newStatus: "Cancelled",
			};

			let requestUrl = new URL("/api/v1/webpay/transaction/update", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let respone = await fetch(`api/v1/webpay/transaction/update`);
				this.transactionStatus = await response.json();

				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		cancelNuvei() {
			this.closeNuveiDialog();
		},
		showNuveiDialog() {
			this.nuveiDialog.showModal();
		},
		closeNuveiDialog() {
			this.toPlayerCashCOC = null;
			this.safeChargeApiResult = null;
			this.nuveiShow = null;
			this.nuveiDialog.close();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: center;
}

#buy-ins {
	display: grid;
	width: 95%;
	margin: auto;
}

#buy-ins {
	/* ****** temp fix for a CSS bug I need to understand ****** */
	top: 115px;
	/* left: 20px; */
}

.input-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 75%;
	margin-top: 2vh;
	margin-left: auto;
	margin-right: auto;
}

.input-section > span {
	display: flex;
	align-items: center;
}

#nuvei-container {
	display: none;
}

#nuvei-container.show {
	display: grid;
	align-items: center;
	position: absolute;
	top: 0;
	margin: auto;
	height: 100%;
	width: 100%;
	background-color: #000;
}

#nuvei-dialog {
	width: 80%;
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
	box-sizing: border-box;
	text-align: center;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

#btn-container a {
	text-decoration: none;
}

#btn-container * {
	margin: 15px;
}

select#offer-payment-type {
	/* display: block; */
	/* margin: auto; */
	/* margin: 0; */
	/* border-radius: 20px; */
	width: 100%;
	height: 5vh;
	text-align: center;
	margin-left: 6vw;
	color: #5d3fd3;
	font-weight: bolder;
	/* border-radius: 20px;
	width: 70%;
	height: 5vh;
	text-align: center; */
}

#payment-type-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 2vh;
	margin-left: 8vw;
	width: 31vw;
}

#balances {
	background: white;
	display: flex;
	align-items: center;
	/* text-align: center; */
	color: #5d3fd3;
	border-radius: 25px;
	width: 65%;
	margin-right: auto;
	margin-left: auto;
	font-size: 17px;
	font-weight: bolder;
	height: 5vh;
	margin-top: 3vh;
}

#toPlayerCashCOC {
	border-radius: 25px;
	width: 100%;
	height: 4.6vh;
	color: #5d3fd3;
	text-align: center;
	margin-right: 3vw;
}

.button-container {
	background: white;
	border-radius: 15px;
	width: 22vw;
	margin-left: auto;
	margin-right: auto;
	margin-top: 4.5vh;
}

.buy-in-btn {
	background: linear-gradient(to right, blue, purple, #fa5f55);
	-webkit-text-fill-color: transparent;
	background-clip: text;
	margin: auto;
	width: 22vw;
	height: 4.5vh;
	font-weight: bolder;
	overflow-wrap: break-word;
	/* margin-top: 3vh; */
}

.button-container:hover {
	width: 22.5vw;
	padding-left: 6px;
}

.current-balance {
	margin: 4px auto;
}

iframe {
	margin: auto;
	width: 90;
	height: 400px;
	border-radius: 12px;
}

/* Need to change this name to buy out and all of the other places that use it */
.buy-ins-container {
	background-image: url("../../public/styleOverrides/encore_online_pixel_bg.png");
	width: 60%;
	border-radius: 12px;
	margin-left: auto;
	margin-right: auto;
	height: 40vh;
}

@media (min-width: 768px) {
	#buy-ins {
		width: 80%;
		margin-left: 20%;
		position: absolute;
	}

	#nuvei-dialog {
		width: 50%;
	}
	iframe {
		margin: auto;
		width: 50%;
	}
}

@media (min-width: 1629px) {
	.playerCashCOC {
		margin-left: 5.5vw;
	}
}

@media (max-width: 767px) {
	.buy-ins-container {
		width: 70%;
		border-radius: 12px;
		margin-left: auto;
		margin-right: auto;
		height: 49vh;
	}

	.button-container {
		background: white;
		border-radius: 15px;
		width: 35vw;
		margin-left: auto;
		margin-right: auto;
		margin-top: 7.5vh;
	}

	.button-container:hover {
		width: 36vw;
	}

	.buy-in-btn {
		background: linear-gradient(to right, blue, purple, #fa5f55);
		-webkit-text-fill-color: transparent;
		background-clip: text;
		margin: auto;
		border-radius: 15px !important;
		width: 35vw;
		height: 4.5vh;
		font-weight: bolder;
		overflow-wrap: break-word;
	}

	.content-container {
		margin-top: 3.5vh;
	}

	#balances {
		width: 90%;
	}

	#toPlayerCashCOC {
		border-radius: 25px;
		width: 100%;
		height: 4.6vh;
		color: #5d3fd3;
		text-align: center;
		margin-right: 0;
	}

	#payment-type-section {
		width: 60%;
		margin-left: auto;
		margin-right: 7vw;
	}

	#payment-type-label {
		margin-left: 9vw;
	}

	select#offer-payment-type {
		width: 21vw;
		height: 5vh;
		text-align: center;
		margin-left: 1vw;
		color: #5d3fd3;
		font-weight: bolder;
	}
}
</style>
